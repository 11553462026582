import React, { useState } from 'react';
import './hire-a-dev/index.css';
// import firstImage from '../images/full-logo.png';
import './hire-a-dev/responsiveHire.css';
import { navigate } from 'gatsby';
import Recaptcha from 'react-google-recaptcha';

const RECAPTCHA_KEY = '6LepaFQiAAAAADYtSZwFEJMwV1VU30iJrm9pIZ8Z';

const ContactPage = () => {
  const recaptchaRef = React.createRef();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitting(true);
    const form = e.target;
    const recaptchaValue = recaptchaRef.current.getValue();

    fetch('/.netlify/functions/contact', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: JSON.stringify({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        name,
        email,
        message,
      }),
    })
      .then(res => res.json())
      .then(({ success }) => {
        console.log(success);
        if (success) {
          navigate('/success');
        }
        setIsSubmitting(false);
      })
      .catch(error => {
        setIsSubmitting(false);
        console.error(error);
      });
  };

  return (
    <>
      <form
        method="POST"
        netlify={true}
        action="/.netlify/functions/contact"
        name="contact-us"
        data-netlify="true"
        data-netlify-recaptcha="true"
        onSubmit={handleSubmit}
      >
        <div className="container">
          {/* <h2 className="contact-us-title">
            Contact Us
          </h2> */}
          <h2>
            We would love to hear <br />
            from you.
          </h2>

          <div className="row">
            <div className="col-12 col-lg-6 content">
              <h3>Have a vision you're eager to bring to life?</h3>
              {/* <p>Whether you are planning a project, interested in getting involved with our mission, or inquiring about a job opportunity, please don’t hesitate to reach out.</p> */}
              {/* <p>Whether you're envisioning a new project, looking to be part of our mission, or exploring career opportunities, your ideas are important to us. Please feel free to get in touch; we're here to listen and collaborate.</p> */}
              <p>
                Your vision is the spark that ignites our collaboration. Whether it's a project, our mission, or a job inquiry, we're here to support your
                goals. Don't hesitate to connect with us; we're eager to embark on this journey with you.
              </p>
              <h4 className="d-none d-lg-block">Social Networks:</h4>
              <ul className="d-none d-lg-block socials">
                {/* {socialLinks.map((item) => ( */}
                <li>
                  <a href={'https://www.linkedin.com/company/coeticx'} target="_blank" rel="noopener noreferrer">
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a href={'https://www.instagram.com/coeticx/'} target="_blank" rel="noopener noreferrer">
                    Instagram
                  </a>
                </li>
                {/* ))} */}
              </ul>

              <h4 className="d-none d-lg-block">Contact Us:</h4>
              <ul className="d-none d-lg-block contact">
                <li>
                  <a href={`tel:+383 45 250 180`}>+383 45 250 180</a>
                </li>
                <li>
                  <a href={`mailto: arber@coetic-x.com`}>arber@coetic-x.com</a>
                </li>
                <li>Rr. Ahmet Krasniqi 5-1, Prishtinë 10000, Kosova</li>
              </ul>
            </div>
            <div className="col-12 col-lg-6">
              {/* <>
            <div className='input-box d-flex position-relative mt-3 mb-2'>
              <span className="material-icons">person</span>
              <input type="text" name="name" value={name} onChange={} placeholder="First and Last Name" required />
            </div>

            <div className='input-box d-flex position-relative mb-3'>
              <span className="material-icons">email</span>
              <input type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="E-Mail" required />
            </div>

            <label htmlFor={"contact-textarea"} className='textarea-box d-flex flex-column position-relative mb-4'>
              <span className='message-title'>Message</span>
              <textarea id="contact-textarea"  type="message" name="message" value={message} onChange={e => setMessage(e.target.value)} required />
            </label>
          </> */}
              {/* <br/> */}
              <div className="form-group">
                <label htmlFor="fullName">Full Name:</label>
                <span className="material-icons">person</span>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  name="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder={'Write full name here'}
                />
                <small className="form-text text-muted" />
              </div>

              <div className="form-group">
                <label htmlFor="email">E-mail:</label>
                <span className="material-icons">mail</span>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  aria-describedby="emailHelp"
                  placeholder={'Write your e-mail address'}
                />
                <small id="emailHelp" className="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>

              {/* <div className="form-group">
										<label htmlFor="subject">{subject.title}</label>
										<input
											type="text"
											className="form-control"
											id="subject"
											name="subject"
											value={subjectField}
											onChange={this.handleChange}
											placeholder={subject.placeholder}
											style={{
												backgroundImage: `url("/icons/${subject.icon}.svg"`,
											}}
										/>
										<small className="form-text text-muted" />
									</div> */}

              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="4"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  placeholder={'Description..'}
                />
              </div>

              {/* <br /> */}
              <div className="submit-box">
                <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} size="normal" id="recaptcha-google" />
                <button type="submit" disabled={isSubmitting}>
                  Send
                </button>
              </div>

              {/* </div> */}
              {/* <div className="hire-page-line"></div>
              <div className="hire-button">
                <button class="button-next" disabled={isSubmitting} type={"submit"}>
                  <span>
                    Submit
                  </span>
                  <p className="material-icons arrow ">arrow_circle_right</p>
                </button>
              </div> */}
              {/* </div> */}
              {/* <div className="first-page-logo">
              <svg id="Group_73" data-name="Group 73" xmlns="http://www.w3.org/2000/svg" width="50.206" height="65.219" viewBox="0 0 50.206 65.219">
                <defs>
                  <clipPath id="clip-path">
                    <path id="Path_1" data-name="Path 1" d="M95.148,550.594v7.247l6.276,3.623,6.276,3.623,6.276,3.623v-7.246L107.7,557.84l-6.276-3.624-6.276-3.623,6.276-3.623v-7.247l-6.276,3.623-6.276,3.624L82.6,550.594v-7.247l-6.276-3.623L70.045,536.1l-6.276-3.623v7.246l6.276,3.623,6.276,3.624,6.276,3.623-6.276,3.623v7.247L82.6,557.84l6.276-3.624Zm6.276,18.117-6.276,3.623-6.276,3.623L82.6,572.334l-6.276-3.624-6.276-3.623V550.594l-6.276-3.623v21.74l6.276,3.624,6.276,3.623L82.6,579.58l6.276,3.623,6.276-3.623,6.276-3.623,6.276-3.623ZM107.7,536.1v7.246l6.276-3.623v-7.246l-6.276-3.624-6.276-3.623-6.276-3.623-6.276-3.623L82.6,521.607l-6.276,3.623-6.276,3.623,6.276,3.624,6.276-3.624,6.276-3.623,6.276,3.623,6.276,3.624Z" transform="translate(-63.769 -517.984)" fill="#fff" clip-rule="evenodd" />
                  </clipPath>
                </defs>
                <g id="Group_1" data-name="Group 1" clip-path="url(#clip-path)">
                  <rect id="Rectangle_1" data-name="Rectangle 1" width="50.206" height="65.22" transform="translate(0 0)" fill="#fff" />
                </g>
              </svg>
            </div>
          </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactPage;
